import React, { SVGProps } from 'react';

export const Menu = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M1.9 2.59998C1.40294 2.59998 1 3.00292 1 3.49998C1 3.99703 1.40294 4.39998 1.9 4.39998H14.1C14.5971 4.39998 15 3.99703 15 3.49998C15 3.00292 14.5971 2.59998 14.1 2.59998H1.9ZM1 7.99998C1 7.50292 1.40294 7.09998 1.9 7.09998H14.1C14.5971 7.09998 15 7.50292 15 7.99998C15 8.49703 14.5971 8.89998 14.1 8.89998H1.9C1.40294 8.89998 1 8.49703 1 7.99998ZM1 12.5C1 12.0029 1.40294 11.6 1.9 11.6H14.1C14.5971 11.6 15 12.0029 15 12.5C15 12.997 14.5971 13.4 14.1 13.4H1.9C1.40294 13.4 1 12.997 1 12.5Z"
        fill="currentcolor"
      />
    </svg>
  );
};
