import React, { SVGProps } from 'react';

export const Membership = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.00195C0 3.58368 3.58172 0.00195312 8 0.00195312C12.4183 0.00195312 16 3.58368 16 8.00195C16 12.4202 12.4183 16.002 8 16.002C3.58172 16.002 0 12.4202 0 8.00195ZM8.7071 4.799C8.31658 4.40847 7.68341 4.40847 7.29289 4.799L4.7971 7.29478C4.40658 7.68531 4.40658 8.31847 4.7971 8.709L7.29289 11.2048C7.68342 11.5953 8.31658 11.5953 8.70711 11.2048L11.2029 8.709C11.5934 8.31847 11.5934 7.68531 11.2029 7.29478L8.7071 4.799Z"
        fill="currentcolor"
      />
      {/* <g clipPath="url(#clip0_4301_103138)">
      </g>
      <defs>
        <clipPath id="clip0_4301_103138">
          <rect width="16" height="16" fill="white" transform="translate(0 0.00195312)" />
        </clipPath>
      </defs> */}
    </svg>
  );
};

export default Membership;
