import React, { SVGProps } from 'react';

export const Moon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.67001 1.00764C7.24034 1.91496 7 2.92941 7 4C7 7.866 10.134 11 14 11C14.1106 11 14.2206 10.9974 14.33 10.9924C13.2081 13.3614 10.7954 15 8 15C4.13401 15 1 11.866 1 8C1 4.24463 3.95723 1.17995 7.67001 1.00764Z"
        fill="currentcolor"
      />
    </svg>
  );
};
